import {
  Fragment,
  Suspense,
  createElement,
  memo,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import type { Thing, WithContext } from "schema-dts";

import type { Location, Params } from "@remix-run/react";
import { Await, useLocation, useMatches } from "@remix-run/react";
import type {
  LoaderFunction,
  SerializeFrom,
  ServerRuntimeMetaDescriptor,
} from "@remix-run/server-runtime";

import { isServer } from "~/lib/utils/utils";

import { type SeoConfig, generateSeoTags } from "./utils/generate-seo-tags";
import SeoLogger from "./utils/log-seo-tags";

export interface SeoHandleFunction<
  Loader extends LoaderFunction | unknown = unknown,
  StructuredDataSchema extends Thing = Thing,
> {
  (args: {
    data: Loader extends LoaderFunction ? SerializeFrom<Loader> : unknown;
    id: string;
    params: Params;
    pathname: Location["pathname"];
    search: Location["search"];
    hash: Location["hash"];
    key: string;
  }): Partial<SeoConfig<StructuredDataSchema>>;
}

interface SeoProps {
  /** Enable debug mode that prints SEO properties for route in the console */
  debug?: boolean;
  data: ServerRuntimeMetaDescriptor[];
}

type SeoWrapper =
  | undefined
  | {
      seo?: ServerRuntimeMetaDescriptor | Promise<ServerRuntimeMetaDescriptor>;
    };
const extractMatches = (matches: any[], location: Location<any>) => {
  if (isServer) {
    return matches.flatMap(match => {
      const { handle, ...routeMatch } = match;
      const routeData = { ...routeMatch, ...location };
      const handleSeo = (handle as AppHandle)?.seo;
      const loaderSeo = (routeMatch?.data as SeoWrapper)?.seo;
      if (!handleSeo && !loaderSeo) {
        return [];
      }
      try {
        // if seo is defined in the handle, invoke it with the route data
        if (handleSeo) {
          return recursivelyInvokeOrReturn(handleSeo, routeData);
        } else if (loaderSeo instanceof Promise) {
          const { data: _data, ...rest } = routeMatch;
          console.error(
            "Loader seo is a promise on serverside ",
            JSON.stringify(rest, null, 2),
          );
          return [];
        } else if (!(loaderSeo instanceof Promise) && loaderSeo) {
          return loaderSeo;
        }
      } catch (e) {
        console.error("Error extracting seo from route", e);
        return [];
      }
    });
  }
  return Promise.all(
    matches.flatMap(async match => {
      const { handle, ...routeMatch } = match;
      const routeData = { ...routeMatch, ...location };
      const handleSeo = (handle as AppHandle)?.seo;
      const loaderSeo = (routeMatch?.data as SeoWrapper)?.seo;
      if (!handleSeo && !loaderSeo) {
        return [];
      }
      // if seo is defined in the handle, invoke it with the route data
      if (handleSeo) {
        return await recursivelyInvokeOrReturn(handleSeo, routeData);
      } else {
        return [await loaderSeo] as const;
      }
    }),
  ).then(data => data.flat());
};
export function DeferSeo({ debug }: { debug?: boolean }) {
  const matches = useMatches();
  const location = useLocation();
  const [seoConfig, setConfig] = useState<Promise<any[]> | any[]>(() =>
    extractMatches(matches, location),
  );
  useMountedEffect(() => {
    setConfig(extractMatches(matches, location));
  }, [matches, location]);

  return (
    <Suspense>
      <Await resolve={seoConfig}>
        {data => {
          return <Seo debug={debug} data={data} />;
        }}
      </Await>
    </Suspense>
  );
}
export function Seo({ debug, data }: SeoProps) {
  // Capture the seo and jsonLd configs from the route matches
  const seoConfig = useMemo(() => {
    return (
      data
        // merge route seo (priority) with the root seo if both are present
        // jsonLd definitions are instead concatenated because there can be
        // multiple jsonLd tags on any given root+route. e.g root renders Organization
        // schema and a product page renders Product schema
        .reduce((acc, current) => {
          // remove seo properties with falsy values
          Object.keys(current).forEach(
            key =>
              key !== "description" &&
              !(current as any)[key] &&
              delete (current as any)[key],
          );

          const { jsonLd } = current as any;

          if (!jsonLd) {
            return { ...acc, ...current };
          }

          // concatenate jsonLds if present
          if (!acc?.jsonLd) {
            return { ...acc, ...current, jsonLd: [jsonLd] };
          } else {
            if (Array.isArray(jsonLd)) {
              return {
                ...acc,
                ...current,
                jsonLd: [
                  ...([] as WithContext<Thing>[]).concat(acc.jsonLd),
                  ...jsonLd,
                ],
              };
            } else {
              return {
                ...acc,
                ...current,
                jsonLd: [...(acc.jsonLd as WithContext<Thing>[]), jsonLd],
              };
            }
          }
        }, {} as SeoConfig<Thing>)
    );
  }, [data]);

  // Generate seo and jsonLd tags from the route seo configs
  // and return the jsx elements as html
  const { html, loggerMarkup } = useMemo(() => {
    const headTags = generateSeoTags(seoConfig);
    const html = headTags.map(tag => {
      if (tag.tag === "script") {
        return createElement(tag.tag, {
          ...tag.props,
          key: tag.key,
          dangerouslySetInnerHTML: { __html: tag.children },
        });
      }

      return createElement(
        tag.tag,
        { ...tag.props, key: tag.key },
        tag.children,
      );
    });

    const loggerMarkup = createElement(
      Suspense,
      { fallback: null },
      createElement(SeoLogger, { headTags }),
    );

    return { html, loggerMarkup };
  }, [seoConfig]);

  return createElement(Fragment, null, html, debug && loggerMarkup);
}

/**
 * Recursively invoke a function or return the value
 * @param value
 * @param rest
 * @returns
 */
export function recursivelyInvokeOrReturn<T, R extends any[]>(
  value: T | ((...rest: R) => T),
  ...rest: R
): T | Record<string, T> {
  if (value instanceof Function) {
    return recursivelyInvokeOrReturn<T, R>(value(...rest), ...rest);
  }

  let result: Record<string, T> = {};

  if (Array.isArray(value)) {
    result = value.reduce((acc, item) => {
      return [...acc, recursivelyInvokeOrReturn(item)];
    }, []);

    return result;
  }

  if (value instanceof Object) {
    const entries = Object.entries(value);

    entries.forEach(([key, val]) => {
      // @ts-expect-error - we know that val is a T
      result[key] = recursivelyInvokeOrReturn<T, R>(val, ...rest);
    });

    return result;
  }

  return value;
}

export const DeferH1Seo = memo(() => {
  const matches = useMatches();
  const location = useLocation();
  const [seoConfig, setConfig] = useState<Promise<any[]> | any[]>(() =>
    extractMatches(matches, location),
  );
  useMountedEffect(() => {
    setConfig(extractMatches(matches, location));
  }, [matches, location]);

  return (
    <Suspense>
      <Await resolve={seoConfig}>
        {data => {
          const h1 = data.reduce((acc, current) => {
            if (!current?.h1) return acc;
            return current.h1 || acc;
          }, "");
          return h1 ? <H1Seo>{h1}</H1Seo> : null;
        }}
      </Await>
    </Suspense>
  );
});
DeferH1Seo.displayName = "DeferH1Seo";
const H1Seo = ({ children }: { children: string }) => {
  return <h1 className="sr-only">{children}</h1>;
};

export const DeferCanonicalLink = memo(() => {
  const matches = useMatches();
  const location = useLocation();
  const [seoConfig, setConfig] = useState<Promise<any[]> | any[]>(() =>
    extractMatches(matches, location),
  );
  useMountedEffect(() => {
    setConfig(extractMatches(matches, location));
  }, [matches, location]);

  return (
    <Suspense>
      <Await resolve={seoConfig}>
        {data => {
          const url = data.reduce((acc, current) => {
            if (!current?.url) return acc;
            return current.url || acc;
          }, "");
          return url ? <DeferCanonicalLinkRender href={url} /> : null;
        }}
      </Await>
    </Suspense>
  );
});
DeferCanonicalLink.displayName = "DeferH1Seo";
const DeferCanonicalLinkRender = ({ href }: { href: string }) => {
  return <link rel="canonical" href={href} />;
};

const useMountedEffect = (fn: () => void, deps: any[]) => {
  const ref = useRef(false);
  useEffect(() => {
    if (!ref.current) {
      ref.current = true;
      return;
    }
    return fn();
  }, deps);
};
